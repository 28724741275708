export default [{
  key: 'competencia', label: 'Competência', tdClass: 'col-2',
},
{
  key: 'numeroParcela', label: 'Parcela', tdClass: 'col-2',
},
{
  key: 'status', label: 'Status',
},
{
  key: 'valor', label: 'Valor (R$)',
},
]
